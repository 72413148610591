.bootstrap-dialog {
  /* dialog types */
  /**
   * Icon animation
   * Copied from font-awesome: http://fontawesome.io/
   **/
  /** End of icon animation **/ }

.bootstrap-dialog .modal-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.bootstrap-dialog .bootstrap-dialog-title {
  color: #fff;
  display: inline-block;
  font-size: 16px; }

.bootstrap-dialog .bootstrap-dialog-message {
  font-size: 14px; }

.bootstrap-dialog .bootstrap-dialog-button-icon {
  margin-right: 3px; }

.bootstrap-dialog .bootstrap-dialog-close-button {
  float: right;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.bootstrap-dialog .bootstrap-dialog-close-button:hover {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100); }

@media (min-width: 1172px) {
  .bootstrap-dialog .modal-xl {
    max-width: 95%; } }

.bootstrap-dialog .modal-lg .bootstrap4-dialog-button:first-child {
  margin-top: 8px; }

.bootstrap-dialog.type-default .modal-header {
  background-color: #fff; }

.bootstrap-dialog.type-default .bootstrap-dialog-title {
  color: #333; }

.bootstrap-dialog.type-info .modal-header {
  background-color: #17a2b8; }

.bootstrap-dialog.type-primary .modal-header {
  background-color: #007bff; }

.bootstrap-dialog.type-secondary .modal-header {
  background-color: #6c757d; }

.bootstrap-dialog.type-success .modal-header {
  background-color: #28a745; }

.bootstrap-dialog.type-warning .modal-header {
  background-color: #ffc107; }

.bootstrap-dialog.type-danger .modal-header {
  background-color: #dc3545; }

.bootstrap-dialog.type-light .modal-header {
  background-color: #f8f9fa; }

.bootstrap-dialog.type-dark .modal-header {
  background-color: #343a40; }

.bootstrap-dialog.size-large .bootstrap-dialog-title {
  font-size: 24px; }

.bootstrap-dialog.size-large .bootstrap-dialog-close-button {
  font-size: 30px; }

.bootstrap-dialog.size-large .bootstrap-dialog-message {
  font-size: 18px; }

.bootstrap-dialog .icon-spin {
  display: inline-block;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear; }

.bootstrap-dialog-footer-buttons {
  display: flex; }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.bootstrap-dialog-header {
  display: contents; }

/*# sourceMappingURL=bootstrap-dialog.css.map */
